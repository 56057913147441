import UIkit from 'uikit';

export default {
    onceMouseOver: false,
    spyScroll: true,
    nbTotalElements: 0,
    init() {
        $('.bloc-onglets').each((indexOnglets, blocOnglets) => {
            this.initBloc(indexOnglets, blocOnglets);
        });
    },

    initBloc(index, bloc) {
        const $bloc = $(bloc);

        $bloc.addClass('bloc-onglets-index-' + index);
        // this.initDimensions($bloc);
        this.initOnglets($bloc);
        // this.initScroll($bloc);
        this.initScrollSpy($bloc);

        setTimeout(() => {
            UIkit.sticky($bloc.find('.onglets-titles'), {
                bottom: true,
                offset: '60px',
            });
        }, 500)

        setInterval( () => {
            window.dispatchEvent(new Event('resize'));
        }, 100);

        /* $(window).resize(() => {
            this.initDimensions($bloc);
        }); */
    },

    initScroll($bloc) {
        console.log('init scroll:', $bloc)
        /*$bloc.on('scroll', (event) => {
            event.preventDefault()
            console.log('scrollEvent:', event)
        })*/

        /*$bloc.on('mouseover', (event) => {
            if(this.onceMouseOver) {
                return false
            }
            this.onceMouseOver = true
            console.log('mouseover:', event)
            $(document).on( 'scroll',(scrollEvent) => {
                scrollEvent.preventDefault()
                console.log('scrollEvent:', scrollEvent)
            })
        })

        $bloc.on('mouseout', (event) => {
            $(document).off('scroll')
            console.log('mouseout:', event)
            this.onceMouseOver = false
        })*/
    },

    initDimensions($bloc) {
        let height = 0;

        if ($(window).width() > 767) {
            $bloc.find('.onglets-contents li').each((liIndex, li) => {
                const $li = $(li);

                height = Math.max(height, $li.innerHeight());
                this.nbTotalElements ++;
            });

            $bloc.find('.onglets-contents').innerHeight(height);

            /*$('.onglets-contents ul').scrollTop($('.onglets-contents ul li:nth-child(2)').position().top);
            console.log('offset:', $('.onglets-contents ul li:nth-child(2)').position())*/
        } else {
            $bloc.find('.onglets-contents').innerHeight('auto');
        }

    },

    initOnglets($bloc) {
        $bloc.find('.onglets-titles li').each((liIndex, li) => {
            const $li = $(li);

            $li.attr('data-index', liIndex);
            $li.find('a').click((event) => {
                event.preventDefault()

                this.selectOnglet($bloc, $li.attr('data-index'));
            });

            $($bloc.find('.onglets-contents li')[liIndex]).attr('data-index', liIndex);
        });
    },

    selectOnglet($bloc, index) {
        const selector = '.onglets-contents ul li:eq(' + index + ')';
        const offset = 200;

        this.spyScroll = false;
        setTimeout(() => {
            this.spyScroll = true;
        }, 500)
        /*for(let i = 1 ; i <= index ; i++) {
            top += $bloc.find('.onglets-contents ul li:eq(' + i + ')').height();
        }*/
        $('html, body').animate({scrollTop: $bloc.find(selector).offset().top - offset});
    },

    initScrollSpy($bloc) {
        this.nbTotalElements = $bloc.find('.onglets-contents li').length
        setInterval(() => {
            /*console.log('top', $('#scroll-content').offset().top);
            console.log('#question-417', $('#question-417').offset().top);*/
            if (this.spyScroll) {
                $bloc.find('.onglets-titles ul li').each((index, element) => {
                    const $this = $(element);
                    const offset = 0;

                    var $container = $bloc.find('.onglets-contents ul li:eq(' + $this.attr('data-index') + ')');
                    var containerOffset = $container.position().top;
                    var containerHeight = $container.outerHeight();
                    var containerBottom = containerOffset + containerHeight;
                    var scrollPosition = $(document).scrollTop() - 600;
                    // console.log($this, scrollPosition, containerBottom, offset, element, index);

                    if(scrollPosition < containerBottom - offset && scrollPosition >= containerOffset - offset){
                        $this.addClass('onglet-active');
                    } else{
                        // console.log(this.nbTotalElements, index, scrollPosition, containerBottom)
                        if (this.nbTotalElements - 1 == index && scrollPosition > containerBottom - offset) {
                            $this.addClass('onglet-active');
                        } else {
                            $this.removeClass('onglet-active');
                        }

                    }
                    // if($this.offset().top < 175)
                });
            }
        }, 500);
    },
}
