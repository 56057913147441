import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import MainNavigation from '../layouts/main-navigation';
import Footer from '../layouts/footer';
import Onglets from '../layouts/bloc-onglets';
import Accordions from '../layouts/bloc-accordion';
import HubspotForm from '../layouts/hubspot-form';
import Search from '../layouts/search';

export default {
    init() {
        // JavaScript to be fired on all pages
        UIkit.use(Icons);
        // UIkit.notification('Hello world.');
        // console.log('hello world 2');
        MainNavigation.init();
        Footer.init();
        Onglets.init();
        HubspotForm.init();
        Search.init();
        Accordions.init();
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
