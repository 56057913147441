export default {
    init() {
        const $navPrimary = $('.nav-primary');
        if ($navPrimary.length) {
            $navPrimary.find('.nav > .menu-item').each((index, element) => {
                console.log(index);
                const $element = $(element);
                if ($element.hasClass('menu-item-has-children')) {
                    const $menuLabel  = $element.find('> a').text();
                    const $subMenu = $element.find('.sub-menu');

                    $subMenu.prepend('<li class="title">' + $menuLabel + '</li>');
                    this.menuHasChildrenItemAddEventlistener($element);
                }
            })
        }
        this.initBodyClick();
        this.initMobileMenu();
    },
    initBodyClick() {
        $(document).click((event) => {
            if($(event.target).parents('.menu-item-has-children').length === 0) {
                $('.menu-item-has-children').removeClass('open');
            }
        });
    },
    menuHasChildrenItemAddEventlistener($elt) {
        $elt.hover(() => {
            $elt.addClass('open');
        }, () => {
            $elt.removeClass('open');
        })
    },
    initMobileMenu() {
        $('.box-menu .btn-menu').click(() => {
            $('.nav-primary').toggleClass('open');
            $('body').toggleClass('sub-nav-open');
            $('.box-menu .btn-menu').toggleClass('not-active').toggleClass('active');
        });
    },
}
