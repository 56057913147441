import UAParser from 'ua-parser-js';

export default {
    $data: {
        strateMobile: {},
        strateDesktop: {},
    },

    /**
     * Init component
     */
    init() {
        this.attachEventsHandlers();
    },

    /**
     * Event handlers
     */
    attachEventsHandlers() {
        $(window).on('resize', () => this.checkPlatform)

        this.checkPlatform()
    },

    checkPlatform() {
        const PARSER = new UAParser();
        const OS = PARSER.getOS();
        const DEVICE = PARSER.getDevice();
        const STRATE = $('.download-strate');

        this.$data.strateMobile = STRATE.find('.strate.mobile');
        this.$data.strateDesktop = STRATE.find('.strate.desktop');

        let buttonIOS = this.$data.strateMobile.find('.button-apple');
        let buttonAndroid = this.$data.strateMobile.find('.button-android');
        let textIOS = this.$data.strateMobile.find('.apple');
        let textAndroid = this.$data.strateMobile.find('.android');
        let buttonMac = this.$data.strateDesktop.find('.button-apple');
        let buttonWindows = this.$data.strateDesktop.find('.button-windows');
        let textMac = this.$data.strateDesktop.find('.apple');
        let textWindows = this.$data.strateDesktop.find('.windows');

        console.log(OS, DEVICE);

        switch (OS.name) {
            case 'Windows': {
                buttonMac.hide();
                textWindows.hide();
                textAndroid.hide();
                textIOS.hide();
                break;
            }

            case 'Mac OS': {
                buttonWindows.hide();
                textMac.hide();
                textAndroid.hide();
                textIOS.hide();
                break;
            }

            case 'iOS': {
                buttonAndroid.hide();
                textMac.hide();
                textWindows.hide();
                textIOS.hide();
                break;
            }

            case 'Android': {
                buttonIOS.hide();
                textMac.hide();
                textWindows.hide();
                textAndroid.hide();
                break;
            }

            default: {
                if (['mobile', 'tablet'].includes(DEVICE.type)) {
                    buttonAndroid.hide();
                    textIOS.hide();
                } else {
                    buttonMac.hide();
                    textWindows.hide();
                }
                break;
            }
        }
    },
}
