import UIKit from 'uikit';

export default {
    init() {
        // console.log('footer');
        $('footer .menu > .menu-item > a').addClass('toggle');
        UIKit.accordion('footer .footer-navigation-mobile .menu', {
            content: '.sub-menu',
            toggle: '.toggle',
        });
    },
}
