import UIkit from 'uikit';

export default {
    init() {
        $('.bloc-accordeons-image').each((indexAccordions, blocAccordions) => {
            this.initBloc(indexAccordions, blocAccordions);
        });
    },

    initBloc(indexAccordions, blocAccordions) {
        const $bloc = $(blocAccordions);
        const id = 'accordion-' + indexAccordions;

        $bloc.attr('id', id);
        UIkit.util.on('#' + id + ' .uk-accordion', 'show', function (event) {
            // console.log('event', event);
            // console.log($(event.srcElement).find('.uk-accordion-title').attr('data-index'));
            const indexActive = $(event.srcElement).find('.uk-accordion-title').attr('data-index');
            $bloc.find('.visual.active').removeClass('active');
            console.log('.visual.index' + indexActive, $bloc.find('.visual.index-' + indexActive));
            setTimeout(function () {
                $bloc.find('.visual.index-' + indexActive).addClass('active');
            }, 100);
        });
    },
}
