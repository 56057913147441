import UIkit from 'uikit';

export default {
    init() {
        this.updateScroll();
        this.addNavClickListener();
        this.addOpenerListener();
        this.initScrollSpy();
        this.spyScroll = true;
    },

    updateScroll() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const ID = urlParams.get('q');

        if (ID) {
            let $target = $($('a[href="#'+ID+'"]')[0]);
            UIkit.scroll('.main-content .list-questions').scrollTo('#' + ID);
            if ($(window).width() > 767) {
                $('html,body').animate({ scrollTop: 0 }, 'slow');
            }
            $('a[href="#' + ID + '"]').addClass('nav-active');
            $('.cta-selectbox .label').text($target.text());
        }

        $('.main-content').on('scroll', (event) => {
            const percentage = 100 * ($(event.currentTarget).scrollTop() / ($('.scroll-content').height() - $('.main-content').height()));
            $('.reading-progress').width(percentage + '%');
        });
    },

    addNavClickListener() {
        $('.nav-container .list-questions a').on('click', (event) => {
            this.scrollTo(event);
        });
    },

    scrollTo(event) {
        const ID = $(event.currentTarget).attr('href').substring(1);
        window.history.replaceState(null, null, '?q=' + ID);
        $('html,body').animate({ scrollTop: 0 }, 'slow');
        $('.list-questions').find('.nav-active').removeClass('nav-active');
        this.spyScroll = false;
        setTimeout(() => {
            this.spyScroll = true;
        }, 1000);
        $('a[href="#' + ID + '"]').addClass('nav-active');
    },

    addOpenerListener() {
        $('body').on('click', (event) => {
            const $target = $(event.target);
            if($target.parents('.sommaire-mobile .cta-selectbox').length) {
                event.preventDefault();
                $('body').toggleClass('open-sommaire-mobile');
            } else {
                $('body').removeClass('open-sommaire-mobile');
                if ($target.hasClass('select-item') || $target.parents('.list-questions li') > 0) {
                    let $target = {};
                    if ($(event.target).hasClass('select-item')) {
                        $target = $(event.target);
                    } else {
                        $target = $target.find('.select-item');
                    }
                    const ID = $target.attr('href').substring(1);
                    window.history.replaceState(null, null, '?q=' + ID);

                    $('.cta-selectbox .label').text($target.text());
                }
            }

            if($target.hasClass('share')) {
                event.preventDefault();

                if ($target.hasClass('copy')) {
                    navigator.clipboard.writeText(location.href);
                    UIkit.notification({message: 'Lien copié dans le presse-papier'});
                } else if ($target.hasClass('mail')) {
                    window.location.href = 'mailto:?subject=' + location.href;
                }
            }

            if($target.hasClass('cta-share')) {
                $('.partage-buttons').toggle();
            } else {
                $('.partage-buttons').hide();
            }
        })
    },

    initScrollSpy() {
        setInterval(() => {
            /*console.log('top', $('#scroll-content').offset().top);
            console.log('#question-417', $('#question-417').offset().top);*/
            if (this.spyScroll) {
                $('.nav-container .list-questions li a').each(function () {
                    const $this = $(this);
                    const offset = 200;

                    var container = $this.attr('href');
                    var containerOffset = $(container).offset().top;
                    var containerHeight = $(container).outerHeight();
                    var containerBottom = containerOffset + containerHeight;
                    var scrollPosition = $(document).scrollTop();

                    if(scrollPosition < containerBottom - offset && scrollPosition >= containerOffset - offset){
                        $this.addClass('nav-active');
                        $('.cta-selectbox .label').text($this.text());
                    } else{
                        $this.removeClass('nav-active');
                    }
                    // if($this.offset().top < 175)
                });
            }
        }, 500);
    },
};
