/*global bodymovin*/

export default {
  init() {
    // JavaScript to be fired on the home page
      const heroBgContainer = document.querySelector('.home-hero .lottie-animation-background');
      // const homeAnimContainer = document.querySelector('.home-hero-animation .lottie-container');
      if (typeof bodymovin !== 'undefined') {
          const heroBgAnimation = bodymovin.loadAnimation({
              container: heroBgContainer,
              renderer: 'svg',
              speed: 1,
              style: 'width: 100%; height: auto;',
              loop: true,
              autoplay: true,
              path: window.location.origin + '/app/themes/speek/resources/assets/json/anim-home-speek.json',
              // path: 'http://local.speek.com/app/themes/speek/resources/assets/json/anim-home-speek.json',
              // path: 'http://local.speek.com/app/themes/speek/ressources/assets/json/anim-home-speek.json
          });

          heroBgAnimation.setSubframe(false);
      }
      /*const homeAnim = bodymovin.loadAnimation({
          container: homeAnimContainer,
          renderer: 'svg',
          speed: 1,
          style: 'width: 100%; height: auto;',
          loop: true,
          autoplay: true,
          path: window.location.origin + '/app/themes/speek/resources/assets/json/animation.json',
          // path: 'http://local.speek.com/app/themes/speek/resources/assets/json/animation.json',
      });*/
      // homeAnim.setSubframe(false);
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
