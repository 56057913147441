export default {
  init() {
    jQuery('.hbspt-form').each(
      function() {
        if(window.hbspt) {
          var $portalId = jQuery(this).attr('data-portal-id'),
              $formId = jQuery(this).attr('data-form-id'),
              $instanceId = jQuery(this).attr('data-instance-id');
          var $el = jQuery(this);

          window.hbspt.forms.create({
            portalId: $portalId,
            formId: $formId,
            formInstanceId: $instanceId,
            target: '#hbspt-form',
            locale: 'fr',
            onFormReady: function () {
              $el.addClass('uk-animation-slide-bottom');
            },
          });
        }
      }
    );
      
  },
}