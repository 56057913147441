export default {
    init() {
        console.log('page fonctionnalités');
        // this.initHeaderHeight();
    },

    /*initHeaderHeight() {
        const $headerContent = $('.fonctionnalite-hero .content');
        let headerHeight = $headerContent.innerHeight();

        $('.fonctionnalite-hero .content-right').height(headerHeight);

        $(window).resize(() => {
            headerHeight = $headerContent.innerHeight();
            $('.fonctionnalite-hero .content-right').height(headerHeight);
        });
    },*/
};
