export default {
    init() {
        const $wrapper = $('.search__wrapper');
        if ($wrapper.length) {
            this.initInput($wrapper);
        }
    },

    initInput($wrapper) {
        const $search = $wrapper.find('.search-questions');
        const $submit = $wrapper.find('.submit-search-questions');

        $submit.click(
            (event) => {
                event.preventDefault();
                this.goSearch($search.val());
            }
        );
        $search.keypress((event) => {
            if(event.which == 13) {
                this.goSearch($search.val());
            }
        });

        $search.on('focusin', () => {
            if ($('.faq__wrapper').length) {
                $('.faq__wrapper').fadeIn();
            }
        });

        $search.on('focusout', () => {
            if ($('.faq__wrapper').length) {
                $('.faq__wrapper').fadeOut();
            }
        })
    },

    goSearch(searchValue) {
        if (searchValue.replace(/^\s+|\s+$/g, '').length != 0){
            location.href = '/recherche/?m=' + searchValue;
        }
    },
}
